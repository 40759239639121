import React from 'react';

const WidgetFooter = () => (
    <a
        className="vocads-widget-footer-link"
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.vocads.com"
    >
        <div className="vocads-widget-content-footer">
            <svg xmlns="http://www.w3.org/2000/svg" width="15.2" height="13" viewBox="0 0 10 20">
                <defs>
                    <clipPath id="clip-path">
                        <path
                            fill="none"
                            d="M55.624 6.118a6.935 6.935 0 00-2.684 3.011 10.28 10.28 0 00-.936 4.5v.978a9.938 9.938 0 001.007 4.353 6.956 6.956 0 002.677 2.916 7.412 7.412 0 003.44 1v-2.912a2.937 2.937 0 01-2.866-2.776h1.687a.466.466 0 000-.933h-1.691v-1.3h1.691a.466.466 0 000-.933h-1.691v-1.3h1.691a.467.467 0 000-.934h-1.691v-1.21a2.939 2.939 0 011.663-2.645v1.324a.466.466 0 10.933 0V7.668c.11-.013 1.187-.013 1.3 0v1.589a.467.467 0 10.933 0V7.938a2.939 2.939 0 011.662 2.645v1.21h-1.692a.467.467 0 000 .934h1.691v1.3h-1.691a.466.466 0 100 .933h1.691v1.3h-1.691a.466.466 0 100 .933h1.686a2.937 2.937 0 01-2.866 2.776v2.927a7.394 7.394 0 003.7-1.049 6.963 6.963 0 002.677-3.012 10.237 10.237 0 00.942-4.5v-.8a9.993 9.993 0 00-.972-4.443 7.076 7.076 0 00-2.69-2.988 7.944 7.944 0 00-7.913.013"
                            transform="translate(-52.004 -5.056)"
                        />
                    </clipPath>
                </defs>
                <path
                    fill="#434343"
                    d="M5.958 5.73L7.8 3.369a24.687 24.687 0 013.212 0c.865.134 3.861 3.971 3.937 4.222s.568 5.633.508 5.952-1.418 2.837-1.418 2.837l-3.459 3.16H9.433l-3.475-3.922L4.821 8.57z"
                    transform="translate(-2.495 -1.713)"
                />
                <g clipPath="url(#clip-path)">
                    <path
                        fill="#f5f5f5"
                        d="M0 0H18.938V21.579H0z"
                        transform="translate(-1.872 -1.872)"
                    />
                </g>
            </svg>
            <span>Powered by Vocads</span>
        </div>
    </a>
);

export default WidgetFooter;
