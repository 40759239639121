import React from 'react';

const MinimizeButtonImage = ({ onClick }) => (
    <div className="vocads-widget-button-container vocads-widget-button-minimize">
        <svg
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            width="24.5"
            height="13.5"
            viewBox="0 0 24.5 12.5"
        >
            <path
                d="M22,0,11,11ZM0,0,11,11Z"
                transform="translate(1.25 1.25)"
                fill="none"
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.4"
            />
        </svg>
    </div>
);

export default MinimizeButtonImage;
