import React from 'react';
import MicButtonSVG from './MicButtonSVG';
import Tooltip from './Tooltip';

const MicButtonWrapper = ({ onClick, iconState, isMain, micButtonTooltip }) => {
    const hasInteracted = localStorage.getItem('vocadsHasInteracted');
    return (
        <div
            className={`vocads-widget-button-container ${
                iconState === 'isListening'
                    ? 'vocads-widget-is-listening'
                    : 'vocads-widget-is-speaking'
            }`}
        >
            {isMain && !hasInteracted ? (
                <Tooltip micButtonTooltip={micButtonTooltip} onClick={onClick} />
            ) : (
                <React.Fragment>
                    {isMain ? null : <div className="vocads-widget-animated-circle-inner" />}
                    <MicButtonSVG onClick={onClick} />
                </React.Fragment>
            )}
        </div>
    );
};

export default MicButtonWrapper;
