export default bottomSpacing => {
    if (bottomSpacing && !Number.isNaN(bottomSpacing) && typeof bottomSpacing === 'number') {
        const css = `
        div.vocads-widget-container-main,
        .vocads-widget-container-main > .vocads-widget-button-container,
        .vocads-widget-button-container,
        .vocads-widget-button-container.vocads-widget-button-minimize {
	        bottom: ${bottomSpacing + 20}px !important;
        }
        `;
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        head.appendChild(style);
        style.setAttribute('type', 'text/css');
        style.setAttribute('id', 'vocads-widget-stylesheet');
        return style.appendChild(document.createTextNode(css));
    }
};
