import React from 'react';
import ReactDOM from 'react-dom';
import WidgetToggle from './components/WidgetToggle';
import './styles/cleanslate.css';
import './styles/index.css';
import './styles/responsive.css';

const vocadsRoot = document.createElement('div');
vocadsRoot.setAttribute('id', 'vocads-widget-root');
vocadsRoot.classList.add('cleanslate');

const App = (
    <div className="vocads-widget-container-main">
        <WidgetToggle />
    </div>
);

if (document.readyState !== 'loading') {
    document.body.appendChild(vocadsRoot);
    ReactDOM.render(App, document.getElementById('vocads-widget-root'));
} else {
    document.addEventListener('DOMContentLoaded', () => {
        document.body.appendChild(vocadsRoot);
        ReactDOM.render(App, document.getElementById('vocads-widget-root'));
    });
}
