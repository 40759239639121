export default {
    isOpen: false,
    noResponseCount: 0,
    visiterId: null,
    widgetId: window.vocadsWidgetId,
    campaignId: null,
    iconState: null,
    userResponse: null, // this is the truncated message by the user
    completeUserResponse: null, // this is the complete response from the use
    currentQuestion: 'Loading...',
    isFirstQuestion: false,
    widgetTitle: null,
    isResponseWarning: null,
    isResuming: false,
    // bufferSize: 2048,
    isDestinationPage: false, // this indicates if the current page is redirected from the previous URL's widget
    isEnd: false,
    isHidden: true,
    isCoupon: false,
    visiterIdGeneratedAt: null,
    language: 'en',
    options: null
};
