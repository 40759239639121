import React from 'react';
import MicButtonWrapper from '../MicButtonWrapper';
import WidgetFooter from './WidgetFooter';
import WidgetHeader from './WidgetHeader';
import Options from './Options';

const WidgetContent = ({
    currentQuestion,
    iconState,
    widgetTitle,
    userResponse,
    coupon,
    isFirstQuestion = true,
    listentingToYouText,
    talkingToYouText,
    allowMicAccessText,
    options
}) => (
    <div
        role="button"
        onClick={e => e.stopPropagation()}
        className="vocads-widget-content-container-main"
    >
        <WidgetHeader
            allowMicAccessText={allowMicAccessText}
            isFirstQuestion={isFirstQuestion}
            widgetTitle={widgetTitle}
        />
        <div className="vocads-widget-content-inner">
            <MicButtonWrapper iconState={iconState} />
            <small className="vocads-widget-mic-status">
                {iconState === 'isListening' ? listentingToYouText : talkingToYouText}
            </small>
            <div className="vocads-widget-question-container">
                <h3 className="vocads-widget-current-question">
                    {userResponse || currentQuestion}
                </h3>
            </div>
            {options ? <Options options={options} /> : null}
            {coupon && (
                <div className="vocads-widget-coupon-container">
                    <span
                        title="Click to copy"
                        onClick={() => navigator.clipboard.writeText(coupon)}
                        className="vocads-widget-coupon"
                    >
                        {'coupon'}
                    </span>
                </div>
            )}

            <WidgetFooter />
        </div>
    </div>
);

export default WidgetContent;
