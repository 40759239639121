import React from 'react';

const Options = ({ options }) => {
    const markup = options.map(({ options: value }) => <li key={value}>{value}</li>);
    return (
        <React.Fragment>
            {/* <h3>{multiModaLText}</h3> */}
            <ul className="options-ul">{markup}</ul>
        </React.Fragment>
    );
};

export default Options;
