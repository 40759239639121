import React from 'react';

export default ({ widgetTitle, isFirstQuestion, allowMicAccessText }) => {
    const micMessage = isFirstQuestion && (
        <React.Fragment>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="13.001"
                viewBox="0 0 13 13.001"
            >
                <path
                    id="Forma_1"
                    d="M7.945,11.47A5.172,5.172,0,0,0,11.556,6.5,5.172,5.172,0,0,0,7.945,1.53V0A6.634,6.634,0,0,1,13,6.5,6.634,6.634,0,0,1,7.945,13ZM2.889,8.723H0V4.276H2.889L6.5.57V12.43ZM7.945,3.516a3.371,3.371,0,0,1,0,5.971Z"
                    transform="translate(0 0.001)"
                    fill="#fff"
                />
            </svg>
            <span>{allowMicAccessText}</span>
        </React.Fragment>
    );
    return (
        <React.Fragment>
            <div className="vocads-widget-ribbon-container">
                <div className="vocads-widget-ribbon">
                    <span>BETA</span>
                </div>
            </div>
            <div className="vocads-widget-content-header">
                <h3 className="vocads-widget-title">{widgetTitle}</h3>
                {micMessage}
            </div>
        </React.Fragment>
    );
};
