module.exports = {
    resumeConversationMessage: 'Would you like to resume your conversation?',
    speakSomethingMessage: 'Please speak something.',
    micButtonTooltip: 'Turn on the sound',
    listentingToYouText: 'Listening to you',
    talkingToYouText: 'Talking to you',
    loadingText: 'Loading...',
    allowMicAccessText: 'Make sure you allow microphone access!',
    warnings: {
        unexpectedError: 'Something went wrong.',
        yesNoTypeResponseNeeded: 'This is Yes or No type of question. Please answer accordingly.',
        invalidVisiterId: 'Something went wrong. It may be due to an invalid visiter ID.',
        unclearResponse: `Sorry I didn't understand, Please speak again.`,
        noResposeEndCampaignMessage:
            'We are ending your campaign because we are not getting any response from you. Thank You.'
    }
};
