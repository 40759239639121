import React from 'react';
import MicButtonSVG from './MicButtonSVG';

const Tooltip = ({ onClick, micButtonTooltip }) => (
    <div className="vocads-widget-tooltip">
        <span className="vocads-widget-tooltip-text">{micButtonTooltip}</span>
        <MicButtonSVG onClick={onClick} />
    </div>
);

export default Tooltip;
